<template>
  <div class="app-management">
    <div class="btns">
      <el-button @click="addApp">添加</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" border>
        <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="name"
            label="应用名称"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="_id"
            label="应用ID"
            align="center"
        >
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            :formatter="(row)=>dateFun.getCurrentDate(true, ['-', ':'], row.createTime)"
        >
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.status === 1" size="mini" type="success" @click="changeStatus(scope.row)">正常</el-button>
            <el-button v-else size="mini" type="warning" @click="changeStatus(scope.row)">已停用</el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
        >
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="editRow(row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteRow(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
<!--    -->
    <el-dialog
        title="编辑"
        :visible.sync="dialogVisible"
        width="500px"
       >
        <div class="edit-form">
          <el-form label-position="left" label-width="80px" :model="editForm">
            <el-form-item label="应用名称">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
            <el-form-item label="应用状态">
              <el-radio v-model="editForm.status" :label="1">启用</el-radio>
              <el-radio v-model="editForm.status" :label="0">停用</el-radio>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editAppSubmit">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {createApp, deleteApp, getAppList, updateStatus} from "@/api/base";
import dateFun from "@/utils/dateFun";

export default {
  name: "appManagement",
  computed: {
    dateFun() {
      return dateFun
    }
  },
  data() {
    return {
      tableData: [],
      dialogVisible:false,
      editForm:{}
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getAppList().then(res => {
        this.tableData = res.data
      })
    },
    deleteRow(row) {
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteApp({_id:row._id}).then(res=>{
          if(res.data.code === 0){
            this.$message({
              message:'删除成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message:'删除失败',
              type: 'warning'
            });
          }
        })
      })
    },
    changeStatus(row){
      updateStatus({_id:row._id,status: row.status===1?0:1}).then(res=>{
        if(res.data.code === 0){
          this.$message({
            message:'操作成功',
            type: 'success'
          });
          this.getList()
        }
      })
    },
    addApp(){
      this.$prompt('请输入应用名称', '添加', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if(!value){
          this.$message({
            message:'请输入应用名称',
            type: 'warning'
          });
          return
        }
        createApp({name:value}).then(res=>{
          if(res.code === 0){
            this.$message({
              message:'操作成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message:'添加失败',
              type: 'warning'
            });
          }
        })
      })
    },
    editRow(row){
      this.editForm = {
        _id: row._id,
        name:row.name,
        status: row.status
      }
      this.dialogVisible = true
    },
    editAppSubmit(){
      updateStatus(this.editForm).then(res=>{
        this.dialogVisible = false
        if(res.code === 0){
          this.$message({
            message:'编辑成功',
            type: 'success'
          });
          this.getList()
        }else{
          this.$message({
            message:'编辑失败',
            type: 'warning'
          });
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btns {
  margin-bottom: 20px;
}
</style>
